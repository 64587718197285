import React, { useEffect, useState } from 'react';
import LeftSideBar from '../LeftSideBar';
import { Spin, Tooltip } from 'antd';
import useFetchFormsByUserId from '../../api/FetchFormsUserId';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LockFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setFormConfiguration } from '../../redux/student/studentSlice';
import InstructionModal from './InstructionModal';

const TestSelection = () => {

    const { response: formsData, isLoading: formsdataLoading, FetchUserForms } = useFetchFormsByUserId();
    const { client_name, test_type } = useParams();
    const clientConfig = useSelector( state => state.clientConfiguration );
    const navigate = useNavigate();

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const user_email = query.get( 'user_email' );
    const user_name = query.get( 'user_name' );
    const user_id = query.get( 'user_id' );

    const testSeriesID = localStorage.getItem( 'testSeriesId' ) || query.get( 'test_series_id' );
    const UserFormDataStored = JSON.parse( sessionStorage.getItem( 'UserResponse' ) );
    const [isModalOpen, setIsModalOpen] = useState( false );
    const [currentTestId, setCurrentTestId] = useState( null );
    const [videoCompletionStatus, setVideoCompletionStatus] = useState( {} );
    const [formId, setFormId] = useState( [] );
    const [userFormData, setUserFormData] = useState();

    const openModal = ( testId ) => {
        setCurrentTestId( testId );
        setIsModalOpen( true );
    };

    useEffect( () => {
        if ( formsData ) {
            sessionStorage.setItem( 'UserResponse', JSON.stringify( formsData ) );
            setUserFormData( formsData )
        } else {
            setUserFormData( UserFormDataStored )
        }
    }, [formsData, UserFormDataStored] )

    const closeModal = () => {
        setIsModalOpen( false );
        setCurrentTestId( null );
    };

    const setIsVideoCompleted = ( testId, status ) => {
        setVideoCompletionStatus( prevState => ( {
            ...prevState,
            [testId]: status
        } ) );
    };

    const dispatch = useDispatch();

    useEffect( () => {
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key !== 'user_id' && key !== 'test_query_params' && key !== 'testSeriesId' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )

    useEffect( () => {
        if ( userFormData ) {
            localStorage.setItem( 'user_id', userFormData.user_id );
            const formIds = userFormData?.forms?.map( form => form.form_id ) || [];
            setFormId( formIds )
        }
    }, [userFormData] )

    useEffect( () => {
        if ( client_name ) {
            sessionStorage.removeItem( 'formConfiguration' );
            dispatch( setFormConfiguration( '' ) );
        }
    }, [client_name] )

    useEffect( () => {
        if ( client_name ) {
            FetchUserForms( client_name, user_id, 'test', testSeriesID )
        }
    }, [client_name] )

    const NavigateToThankYouPage = () => {
        navigate( `/${ client_name }/thank_you/?completed=true`, { state: { formIds: formId } } )
    }

    return (
        <div className="container-fluid">
            <div className="row row-height">
                {
                    formsdataLoading &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div className="container mx-auto p-4 font-poppins">
                            <div className="flex items-center justify-center w-full h-full flex-col">
                                <div className="w-full overflow-x-auto">
                                    <h3 className='text-left text-black text-2xl font-bold'>List of Assessments:</h3>
                                    <table className="table-auto w-full mt-3">
                                        <thead>
                                            <tr>
                                                <th className="px-2 sm:px-4 py-3 text-left lg:text-lg sm:text-base font-bold text-black border-2">Assessment Name</th>
                                                <th className="px-2 sm:px-4 py-3 text-center lg:text-lg sm:text-base font-bold text-black border-2">Status</th>
                                                <th className="px-2 sm:px-4 py-3 text-center lg:text-lg sm:text-base font-bold text-black border-2">Duration</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            { userFormData?.forms?.map( ( test, index ) => (
                                                <React.Fragment key={ test.test_id }>
                                                    { currentTestId === test.test_id && (
                                                        <InstructionModal
                                                            isModalOpen={ isModalOpen }
                                                            handleCancel={ closeModal }
                                                            setIsVideoCompleted={ ( testId, status ) => setIsVideoCompleted( testId, status ) }
                                                            youtubeURL="https://youtu.be/cxQMLCtoi_w?si=TNwmLEZHeXtqNft8"
                                                            testId={ test.test_id }
                                                        />
                                                    ) }
                                                    <tr>
                                                        <td className="px-2 sm:px-4 py-3 text-base sm:text-base text-left font-medium text-black border-2">{ test.form_name }</td>
                                                        <td className="px-2 sm:px-4 py-3 text-center text-base sm:text-base font-medium text-black border-2">
                                                            { test.is_completed === 3 || ( test.duration > 0 && test.is_completed === 2 ) ? (
                                                                <div className='flex flex-row items-center w-full justify-center gap-2'>
                                                                    <CheckCircleFilled style={ { color: '#20C997', fontSize: '24px' } } />
                                                                    <p className='text-[#20C997] font-semibold py-2 sm:text-sm'> Submitted</p>
                                                                </div>
                                                            ) : (
                                                                <button
                                                                        type="button"
                                                                        className={ `lg:px-8 sm:px-4 rounded-lg py-2 sm:text-sm ${ test.is_completed === 2 && test.duration === 0
                                                                            ? `bg-white`
                                                                            : `text-white`
                                                                            }` }
                                                                        style={ {
                                                                            cursor: test.is_completed === 3 ? 'not-allowed' : 'pointer',
                                                                            backgroundColor: test.is_completed === 3
                                                                                ? `${ clientConfig.primary_color }95`
                                                                                : ( test.is_completed === 2 && test.duration === 0 )
                                                                                    ? 'white'
                                                                                    : clientConfig.primary_color,
                                                                            borderColor: ( test.is_completed === 2 && test.duration === 0 )
                                                                                ? clientConfig.primary_color
                                                                                : 'transparent',
                                                                            color: ( test.is_completed === 2 && test.duration === 0 )
                                                                                ? clientConfig.primary_color
                                                                                : 'white',
                                                                            borderWidth: test.is_completed === 2 && test.duration === 0 ? '1px' : '0',
                                                                        } }
                                                                        onClick={ () => {
                                                                            if ( test.is_completed === 0 || test.is_completed === 2 || test.is_completed === 1 ) {
                                                                                navigate(
                                                                                    `/${ client_name }/${ test_type }/${ test?.test_id }/instructions/?user_email=${ user_email }&user_name=${ user_name }&user_id=${ user_id }`,
                                                                                    { state: { test_series_id: testSeriesID } }
                                                                                );
                                                                            }
                                                                        } }
                                                                    >
                                                                        { test.is_completed === 2 && test.duration === 0 ? 'Continue' : 'Take Test' }
                                                                    </button>
                                                            ) }
                                                        </td>
                                                        <td className="px-2 sm:px-4 py-3 text-center text-base sm:text-base font-medium text-black border-2">
                                                            {
                                                                test.duration > 0 ?
                                                                    `${ Math.floor( test.duration / 60 ) } min` :
                                                                    'Untimed'
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ) ) }
                                        </tbody>
                                    </table>
                                </div>
                                { userFormData?.forms?.every( ( test ) => ( test.is_completed === 3 || test.is_completed === 2 ) ) ? (
                                    <button
                                        style={ { backgroundColor: clientConfig.primary_color } }
                                        className="text-white text-center align-center px-2 rounded-lg py-2 mt-4 text-base sm:text-lg"
                                        onClick={ NavigateToThankYouPage }
                                    >

                                        View My Report
                                    </button>
                                ) : (
                                    <div className="flex items-center justify-center mt-4">
                                        <Tooltip title="Please complete all the tests" placement='bottom' arrow={ false }>
                                            <button className="text-black bg-yellow-300 opacity-50 px-2 rounded-lg py-2 text-base sm:text-lg text-center align-center">
                                                <LockFilled style={ { color: 'black' } } className='mr-2 align-middle' />
                                                View My Report
                                            </button>
                                        </Tooltip>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default TestSelection
