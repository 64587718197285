import React, { useState, useEffect } from "react";
import { Select, Input, Spin } from 'antd';
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import { dynamicData } from "../../helpers/data";
import useFetchAllCountries from "../../api/FetchAllCountries";
import useFetchAllStates from "../../api/FetchAllStates";
import useFetchAllCities from '../../api/FetchAllCities';
import { useEffectOnce } from "react-use";

// const { Option } = Select;

// const AddressTypeQuestion = ( { question, isPagination, answer, onAnswerChange, isQuestionNavigation, onNext, onBack, onClearAll } ) => {

//     const clientConfig = useSelector( state => state.clientConfiguration );

//     const [address, setAddress] = useState( {
//         houseNumber: '',
//         country: '',
//         state: '',
//         city: '',
//         pincode: '',
//     } );

//     const [isAnswerCompleted, setIsAnswerCompleted] = useState( false );
//     const [listOfCountries, setListOfCountries] = useState( [] );
//     const [listOfStates, setListOfStates] = useState( [] );
//     const [listOfCities, setListOfCities] = useState( [] );

//     // Validation states for required fields
//     const [countryError, setCountryError] = useState( false );
//     const [pincodeError, setPincodeError] = useState( false );
//     const [stateError, setStateError] = useState( false );

//     const { response: allCountries, error: countriesError, isLoading: countriesLoading, FetchCountries } = useFetchAllCountries();
//     const { response: allStates, error: statesError, isLoading: statesLoading, FetchStates } = useFetchAllStates();
//     const { response: allCities, error: citiesError, isLoading: citiesLoading, FetchCities } = useFetchAllCities();

//     useEffect( () => {
//         const initialAddress = answer || {
//             houseNumber: '',
//             country: '',
//             state: '',
//             city: '',
//             pincode: '',
//         };
//         setAddress( initialAddress );
//     }, [answer, question] );

//     useEffect( () => {
//         FetchCountries();
//     }, [] );

//     useEffect( () => {
//         if ( allCountries && allCountries.data && allCountries.data.countries ) {
//             setListOfCountries( allCountries.data.countries );
//         }
//     }, [allCountries] );

//     useEffect( () => {
//         if ( address.country ) {
//             FetchStates( address.country );
//         }
//     }, [address.country] );

//     useEffect( () => {
//         if ( allStates && allStates.data && allStates.data.states ) {
//             setListOfStates( allStates.data.states );
//         }
//     }, [allStates] );

//     useEffect( () => {
//         if ( address.state ) {
//             FetchCities( address.country, address.state );
//         }
//     }, [address.state] );

//     useEffect( () => {
//         if ( allCities && allCities.data && allCities.data.cities ) {
//             setListOfCities( allCities.data.cities );
//         }
//     }, [allCities] );

//     useEffect( () => {
//         if ( address.country && address.state && address.pincode ) {
//             setIsAnswerCompleted( true );
//         } else {
//             setIsAnswerCompleted( false );
//         }
//     }, [address] );

//     const handleAddressChange = ( key, value ) => {
//         const newAddress = {
//             ...address,
//             [key]: value,
//         };

//         setAddress( newAddress );
//         onAnswerChange( newAddress );

//         // Clear validation error when user corrects the input
//         if ( key === 'country' && value ) setCountryError( false );
//         if ( key === 'pincode' && value ) setPincodeError( false );
//         if ( key === 'state' && value ) setStateError( false );
//     };

//     const handleClearAll = () => {
//         const clearedAddress = {
//             houseNumber: '',
//             country: '',
//             state: '',
//             city: '',
//             pincode: '',
//         };
//         setAddress( clearedAddress );
//         onClearAll();
//     };

//     const handleNext = () => {
//         let hasError = false;

//         if ( !address.country ) {
//             setCountryError( true );
//             hasError = true;
//         }
//         if ( !address.pincode ) {
//             setPincodeError( true );
//             hasError = true;
//         }
//         if ( !address.state ) {
//             setStateError( true );
//             hasError = true;
//         }

//         if ( !hasError ) {
//             onNext();
//         }
//     };

// const perQuestionTimer = dynamicData.default.perQuestionTimer;

//     return (
//         <div key={ question.id } className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
//             <div className="flex flex-col lg:h-full overflow-y-auto">
//                 <QuestionAndDescription question={ question } />
//                 <div className="flex flex-col gap-[0.5rem] mt-2">
//                     <div className="flex flex-col gap-1">
//                         <p className="text-black font-normal text-lg">Country/Region* { countryError && <p className="text-red-500 text-sm">Please select a country.</p> }</p>
//                         <Select
//                             showSearch={ true }
//                             placeholder="Select Country"
//                             onChange={ ( value ) => handleAddressChange( 'country', value ) }
//                             className={ `address-dropdown mb-3 text-lg lg:w-1/2 sm:w-full ${ countryError ? 'border-red-500' : '' }` }
//                             value={ address.country || undefined }
//                         >
//                             { listOfCountries.map( ( country, index ) => (
//                                 <Option key={ index } value={ country }>
//                                     { country }
//                                 </Option>
//                             ) ) }
//                         </Select>
//                     </div>

//                     <div className="flex flex-col gap-1">
//                         <p className="text-black font-normal text-lg">
//                             Pincode*
//                             { pincodeError && <p className="text-red-500 text-sm">Please enter a valid pincode.</p> }
//                         </p>
//                         <Input
//                             placeholder="6 digits [0-9] PIN CODE*"
//                             value={ address.pincode }
//                             onChange={ ( e ) => {
//                                 // Ensure only numeric input and restrict to 6 digits
//                                 const value = e.target.value;
//                                 if ( /^\d{0,6}$/.test( value ) ) {
//                                     handleAddressChange( 'pincode', value );
//                                 }
//                             } }
//                             className={ `mb-3 p-2 text-lg lg:w-1/2 sm:w-full ${ pincodeError ? 'border-red-500' : '' }` }
//                         />
//                     </div>

//                     <div className="flex flex-col gap-1">
//                         <p className="text-black font-normal text-lg">Flat / House, Building, Apartment</p>
//                         <Input
//                             placeholder="1-11-98/170, Hustle Hub, Marathalli"
//                             value={ address.houseNumber }
//                             onChange={ ( e ) => handleAddressChange( 'houseNumber', e.target.value ) }
//                             className="mb-3 p-2 text-lg lg:w-1/2 sm:w-full"
//                         />
//                     </div>

//                     <div className="flex lg:flex-row sm:flex-col lg:w-1/2 sm:w-full gap-6">
//                         <div className="flex flex-col gap-1 w-1/2">
//                             <p className="text-black font-normal text-lg">State* { stateError && <p className="text-red-500 text-sm">Please select a state.</p> }
//                             </p>
//                             <Select
//                                 showSearch={ true }
//                                 placeholder="Select State"
//                                 onChange={ ( value ) => handleAddressChange( 'state', value ) }
//                                 disabled={ !address.country }
//                                 className={ `address-dropdown mb-3 text-lg w-full ${ stateError ? 'border-red-500' : '' }` }
//                                 value={ address.state || undefined }
//                             >
//                                 { listOfStates.map( ( state, index ) => (
//                                     <Option key={ index } value={ state }>
//                                         { state }
//                                     </Option>
//                                 ) ) }
//                             </Select>
//                         </div>

//                         <div className="flex flex-col gap-1 w-1/2">
//                             <p className="text-black font-normal text-lg">City</p>
//                             <Select
//                                 showSearch={ true }
//                                 placeholder="Select City"
//                                 onChange={ ( value ) => handleAddressChange( 'city', value ) }
//                                 disabled={ !address.state }
//                                 className="address-dropdown mb-3 text-lg w-full"
//                                 value={ address.city || undefined }
//                             >
//                                 { listOfCities.map( ( city, index ) => (
//                                     <Option key={ index } value={ city }>
//                                         { city }
//                                     </Option>
//                                 ) ) }
//                             </Select>
//                         </div>
//                     </div>
//                 </div>
//             </div>
// {
//     isPagination ?
//         <></>
//         :
//         isQuestionNavigation ?
//             <>
//                 <SubmitButton onNext={ handleNext } onClearAll={ handleClearAll } />
//             </>
//             :
//             <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
//                 {
//                     question?.question_optional === "optional" && (
//                         <button className="skip-button" onClick={ onNext }>
//                             Skip
//                         </button>
//                     )
//                 }
//                 {
//                     perQuestionTimer ?
//                         <></> :
//                         <button className="back-button" onClick={ onBack }>
//                             Back
//                         </button>

//                 }
//                 <button
//                     className="next-button"
//                     onClick={ isAnswerCompleted ? handleNext : null }
//                     style={ {
//                         backgroundColor: isAnswerCompleted ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
//                         cursor: isAnswerCompleted ? 'pointer' : 'not-allowed'
//                     } }
//                 >
//                     Next
//                 </button>
//             </div>
// }
//         </div>
//     );
// };

// export default AddressTypeQuestion;

const { Option } = Select;

const AddressTypeQuestion = ( { question, isPagination, answer, onAnswerChange, isQuestionNavigation, onNext, onBack, onClearAll } ) => {

    const clientConfig = useSelector( state => state.clientConfiguration );

    const [address, setAddress] = useState( {
        houseNumber: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
    } );

    const [isAnswerCompleted, setIsAnswerCompleted] = useState( false );
    const [listOfCountries, setListOfCountries] = useState( [] );
    const [listOfStates, setListOfStates] = useState( [] );
    const [listOfCities, setListOfCities] = useState( [] );

    // Validation states for required fields
    const [countryError, setCountryError] = useState( false );
    const [pincodeError, setPincodeError] = useState( false );
    const [stateError, setStateError] = useState( false );

    const { response: allCountries, error: countriesError, isLoading: countriesLoading, FetchCountries } = useFetchAllCountries();
    const { response: allStates, error: statesError, isLoading: statesLoading, FetchStates } = useFetchAllStates();
    const { response: allCities, error: citiesError, isLoading: citiesLoading, FetchCities } = useFetchAllCities();

    useEffect( () => {
        const initialAddress = answer || {
            houseNumber: '',
            country: '',
            state: '',
            city: '',
            pincode: '',
        };
        setAddress( initialAddress );
    }, [answer, question] );

    useEffect( () => {
        FetchCountries();
    }, [] );

    useEffect( () => {
        if ( address.country ) {
            FetchStates( address.country );
        }
    }, [address] );

    useEffect( () => {
        if ( address.state ) {
            FetchCities( address.country, address.state );
        }
    }, [address] );

    useEffect( () => {
        if ( allCountries && allCountries.data && allCountries.data.countries ) {
            setListOfCountries( allCountries.data.countries );
        }
    }, [allCountries] );

    useEffect( () => {
        if ( allStates && allStates.data && allStates.data.states ) {
            setListOfStates( allStates.data.states );
        }
    }, [allStates] );

    useEffect( () => {
        if ( allCities && allCities.data && allCities.data.cities ) {
            setListOfCities( allCities.data.cities );
        }
    }, [allCities] );

    useEffect( () => {
        // If no states and cities exist, allow user to move forward
        if ( address.country && listOfStates.length === 0 && listOfCities.length === 0 && address.pincode ) {
            setIsAnswerCompleted( true );
        }
        // If country, state, and pincode are selected
        else if ( address.country && address.state && address.pincode ) {
            setIsAnswerCompleted( true );
        } else {
            setIsAnswerCompleted( false );
        }
    }, [address, listOfStates, listOfCities] );

    const handleAddressChange = ( key, value ) => {
        const newAddress = {
            ...address,
            [key]: value,
        };

        setAddress( newAddress );
        onAnswerChange( newAddress );

        // Clear validation error when user corrects the input
        if ( key === 'country' && value ) setCountryError( false );
        if ( key === 'pincode' && value ) setPincodeError( false );
        if ( key === 'state' && value ) setStateError( false );
    };

    // Handle country change and reset state and city
    const handleCountryChange = ( value ) => {
        // Set country and reset state and city
        setAddress( ( prevAddress ) => ( {
            ...prevAddress,
            country: value,
            state: '',
            city: '',
        } ) );
        onAnswerChange( {
            ...address,
            country: value,
            state: '',
            city: '',
        } );

        FetchStates( value );
        setListOfCities( [] ); // Reset city list when country changes
    };

    // Handle state change and reset city
    const handleStateChange = ( value ) => {
        // Set state and reset city
        setAddress( ( prevAddress ) => ( {
            ...prevAddress,
            state: value,
            city: '',
        } ) );
        onAnswerChange( {
            ...address,
            state: value,
            city: '',
        } );

        FetchCities( address.country, value );
    };

    const handleClearAll = () => {
        const clearedAddress = {
            houseNumber: '',
            country: '',
            state: '',
            city: '',
            pincode: '',
        };
        setAddress( clearedAddress );
        onClearAll();
    };

    const handleNext = () => {
        let hasError = false;

        if ( !address.country ) {
            setCountryError( true );
            hasError = true;
        }
        if ( !address.pincode ) {
            setPincodeError( true );
            hasError = true;
        }
        // Only validate state if the list of states is not empty
        if ( listOfStates.length > 0 && !address.state ) {
            setStateError( true );
            hasError = true;
        }

        if ( !hasError ) {
            onNext();
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div key={ question.id } className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="flex flex-col gap-[0.5rem] mt-2">
                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">Country/Region* { countryError && <p className="text-red-500 text-sm">Please select a country.</p> }</p>
                        <Select
                            showSearch={ true }
                            placeholder="Select Country"
                            onChange={ ( value ) => handleCountryChange( value ) }
                            className={ `address-dropdown mb-3 text-lg lg:w-full sm:w-full ${ countryError ? 'border-red-500' : '' }` }
                            value={ address.country || undefined }
                        >
                            { listOfCountries.map( ( country, index ) => (
                                <Option key={ index } value={ country }>
                                    { country }
                                </Option>
                            ) ) }
                        </Select>
                    </div>

                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">
                            Pincode*
                            { pincodeError && <p className="text-red-500 text-sm">Please enter a valid pincode.</p> }
                        </p>
                        <Input
                            placeholder="6 digits [0-9] PIN CODE*"
                            value={ address.pincode }
                            onChange={ ( e ) => {
                                // Ensure only numeric input and restrict to 6 digits
                                const value = e.target.value;
                                if ( /^\d{0,6}$/.test( value ) ) {
                                    handleAddressChange( 'pincode', value );
                                }
                            } }
                            className={ `mb-3 p-2 text-lg lg:w-full sm:w-full ${ pincodeError ? 'border-red-500' : '' }` }
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">Flat / House, Building, Apartment</p>
                        <Input
                            placeholder="1-11-98/170, Hustle Hub, Marathalli"
                            value={ address.houseNumber }
                            onChange={ ( e ) => handleAddressChange( 'houseNumber', e.target.value ) }
                            className="mb-3 p-2 text-lg lg:w-full sm:w-full"
                        />
                    </div>

                    <div className="flex lg:flex-row sm:flex-col lg:w-full sm:w-full gap-6">
                        <div className="flex flex-col gap-1 w-1/2">
                            <p className="text-black font-normal text-lg">State* { stateError && <p className="text-red-500 text-sm">Please select a state.</p> }
                            </p>
                            <Select
                                showSearch={ true }
                                placeholder="Select State"
                                onChange={ ( value ) => handleStateChange( value ) }
                                disabled={ ( !address.country ) || ( listOfStates && listOfStates.length === 0 ) } // Disable if no country is selected or no states are available
                                className={ `address-dropdown mb-3 text-lg w-full ${ stateError ? 'border-red-500' : '' }` }
                                value={ address.state || undefined }
                            >
                                { listOfStates.map( ( state, index ) => (
                                    <Option key={ index } value={ state }>
                                        { state }
                                    </Option>
                                ) ) }
                            </Select>
                        </div>

                        <div className="flex flex-col gap-1 w-1/2">
                            <p className="text-black font-normal text-lg">City</p>
                            <Select
                                showSearch={ true }
                                placeholder="Select City"
                                onChange={ ( value ) => handleAddressChange( 'city', value ) }
                                disabled={ !address.state || listOfCities.length === 0 } // Disable if no state is selected or no cities are available
                                className="address-dropdown mb-3 text-lg w-full"
                                value={ address.city || undefined }
                            >
                                { listOfCities.map( ( city, index ) => (
                                    <Option key={ index } value={ city }>
                                        { city }
                                    </Option>
                                ) ) }
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ handleNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext }>
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack }>
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isAnswerCompleted ? handleNext : null }
                                style={ {
                                    backgroundColor: isAnswerCompleted ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isAnswerCompleted ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
            {
                countriesLoading || statesLoading || citiesLoading &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
        </div>
    );
};

export default AddressTypeQuestion;
