import React, { useState, useEffect } from "react";
import { Button, Select, notification } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import useFetchCourses from "../../api/FetchCourses";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const { Option } = Select;

const DropDownStepSingleSelect = ( { question, selectedOptionsFromParent, onSelect, onNext, onBack, courseName, selectedCourseName, isPagination, isQuestionNavigation, isValidation, onClearAll } ) => {
    const [selected, setSelected] = useState( selectedOptionsFromParent || [] );
    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const [courses, setCourses] = useState();
    const [courseSelected, setCourseSelected] = useState( selectedCourseName || [] );
    const [isCourseOptions, setIsCourseOptions] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { data, isLoading, isError } = useFetchCourses();
    //useEffect( () => {
    //  const validSelectionExists = question?.options?.some( option => selected === option.id );
    //  setIsOptionSelected( validSelectionExists );
    //}, [question, selected] );

    const handleSelect = ( selectedOptionId ) => {
        if ( selectedOptionId.length > 1 ) {
            return
        }
        const normalizedId = Array.isArray( selectedOptionId ) ? selectedOptionId[0] : selectedOptionId;
        const selectedOption = question.options.find( option => option.id === normalizedId );

        setSelected( normalizedId );
        if ( selectedOption ) {
            onSelect( selectedOptionId );
        } else {
            courseName( normalizedId );
        }

        if ( typeof normalizedId === 'string' ) {
            onSelect( normalizedId );
        }

        setIsOptionSelected( normalizedId != null );
        isValidation( normalizedId != null )
        TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
    };

    const handleCourseSelect = ( value ) => {
        setCourseSelected( value )
        courseName( value );
        setIsOptionSelected( value != null );
        isValidation( value != null );
    };

    useEffect( () => {
        setSelected( selectedOptionsFromParent || [] );
        setCourseSelected( selectedCourseName || [] );
        if ( selectedOptionsFromParent ) {
            setIsOptionSelected( true )
            isValidation( true )
        } else if ( selectedCourseName ) {
            setIsOptionSelected( true )
            isValidation( true )
        } else {
            setIsOptionSelected( false );
            isValidation( false )
        }
    }, [selectedOptionsFromParent, selectedCourseName] );

    useEffect( () => {
        if ( question?.text && question.text.includes( "What course are you pursuing?" ) ) {
            setCourses( data?.data.streams_and_specializations );
            setIsCourseOptions( true );
        }
    }, [question, data] );

    const { client_name } = useParams();

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === 'Enter' ) {
            e.preventDefault(); // Prevent default behavior (opening the dropdown)
            if ( isQuestionNavigation ) {
                notification.info( {
                    message: 'Please click on Save & Next.'
                } )
            }
        }
    };

    const handleClearAll = () => {
        handleSelect( [] )
        handleCourseSelect( [] )
        onClearAll()
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                {
                    !isCourseOptions ?
                        <>
                            <Select
                                className="mb-3 mt-2 text-xl lg:w-1/2 sm:w-full"
                                value={ selected }
                                showSearch={ true }
                                filterOption={ ( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                                onChange={ handleSelect }
                                placeholder={ question?.placeholder_text }
                                onKeyDown={ handleKeyDown }
                                mode="tags"
                            >
                                { question?.options?.map( ( option ) => (
                                    <Option key={ option.id } value={ option.id }>
                                        { option.value }
                                    </Option>
                                ) ) }
                            </Select>
                        </>
                        :
                        <>
                            <Select
                                showSearch={ true }
                                className="mb-3 mt-2 text-xl lg:w-1/2 sm:w-full"
                                value={ courseSelected }
                                onChange={ handleCourseSelect }
                                placeholder={ question?.placeholder_text }
                            >
                                { courses?.map( ( course, index ) => (
                                    <Option key={ index } value={ course }>
                                        { course }
                                    </Option>
                                ) ) }
                            </Select>
                        </>
                }
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default DropDownStepSingleSelect;
