import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';
import SubmitButton from "../SubmitButton/SubmitButton";

const FlexibleInputStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation, onClearAll } ) => {
    const [isAnswerNumeric, setIsAnswerNumeric] = useState( false );

    const clientConfig = useSelector( state => state.clientConfiguration );

    useEffect( () => {
        if ( answer ) {
            setIsAnswerNumeric( true );
            isValidation( true );
        } else {
            setIsAnswerNumeric( false );
            isValidation( false );
        }
    }, [question, answer] );

    const handleInputChange = ( e ) => {
        const inputValue = e.target.value;
        setIsAnswerNumeric( true );
        isValidation( true );
        onAnswerChange( inputValue );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );

        if ( question.text.includes( "Enter your school's address" ) ) {
            localStorage.setItem( 'userAddress', inputValue );
        }
    };


    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        handleInputChange( { target: { value: '' } } );
        onClearAll()
    }

    // Utility function to remove HTML tags
    const stripHtmlTags = ( html ) => {
        return html?.replace( /<\/?[^>]+(>|$)/g, "" );
    };

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        type="text"
                        className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        value={ answer }
                        onChange={ ( e ) => handleInputChange( e, question.validation ) }
                        placeholder={ stripHtmlTags( question?.placeholder_text ) }
                    />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isAnswerNumeric ? onNext : null }
                                style={ {
                                    backgroundColor: isAnswerNumeric ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isAnswerNumeric ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default FlexibleInputStep;

